html {
  font-size: 10px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

@font-face {
  font-family: "SourceHanSansCN-Regular";
  font-weight: 400;
  src: url("./assets/fonts/SourceHanSansCN/Regular/GaDRHiTfCODTwkAEnpFuj.woff2") format("woff2"),
    url("./assets/fonts/SourceHanSansCN/Regular/GaDRHiTfCODTwkAEnpFuj.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "SourceHanSansCN-Medium";
  font-weight: 500;
  src: url("./assets/fonts/SourceHanSansCN/Medium/BI1nz8MctYUQQrby0fj8o.woff2") format("woff2"),
    url("./assets/fonts/SourceHanSansCN/Medium/BI1nz8MctYUQQrby0fj8o.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "SourceHanSansCN-Bold";
  font-weight: 700;
  src: url("./assets/fonts/SourceHanSansCN/Bold/ugdOHJpQXIveE5mo8KT_I.woff2") format("woff2"),
    url("./assets/fonts/SourceHanSansCN/Bold/ugdOHJpQXIveE5mo8KT_I.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "PingFangSC-Medium";
  src: url("./assets/fonts/PingFang/Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "PingFangSC-Regular";
  src: url("./assets/fonts/PingFang/Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "D-DIN-Bold";
  src: url("./assets/fonts/DinBold/din-bold-2.ttf") format("truetype");
  font-display: swap;
}

/* 滚动 */
.scroll {
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 8px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);
}